import type { ComponentType, JSX } from 'react';
import { useCallback, useMemo } from 'react';
import type { NavLinkProps } from 'react-router-dom';
import { NavLink } from 'react-router-dom';

import AppstoreOutlined from '@ant-design/icons/AppstoreOutlined';
import BlockOutlined from '@ant-design/icons/BlockOutlined';
import HomeOutlined from '@ant-design/icons/HomeOutlined';
import IdcardFilled from '@ant-design/icons/IdcardFilled';
import IdcardOutlined from '@ant-design/icons/IdcardOutlined';
import LineChartOutlined from '@ant-design/icons/LineChartOutlined';
import QuestionCircleOutlined from '@ant-design/icons/QuestionCircleOutlined';
import Menu from 'antd/lib/menu';
import Popover from 'antd/lib/popover';

import type { WithClass } from 'common/common-types';
import { disabledFn } from 'common/constants/common-constants';
import { HELP_URL } from 'common/constants/site-constants';
import { useIsImpersonate } from 'common/hooks/use-is-impersonate';
import { useToggle } from 'common/hooks/use-toggle';
import * as routesConstants from 'common/routes/routes-constants';
import { useShowUserManagement } from 'features/user/account/user-management/common/use-show-user-management';
import { useExitImpersonate, useLogout } from 'features/user/auth/user-auth-hooks';

import styles from './AppSidebar.module.less';

const containerClass = `flex flex-column align-center justify-between full-height ${styles.sidebar}`;
const defaultLinkClass = `flex flex-column align-center justify-center full-width ${styles.sidebarLink}`;
const activeLinkClass = `active ${styles.sidebarLinkActive}`;

interface SidebarLink extends NavLinkProps {
  icon: ComponentType<WithClass>;
  label: string;
}

const topItems: SidebarLink[] = [
  {
    icon: HomeOutlined,
    label: 'home',
    to: routesConstants.DASH_PAGE_URL,
    end: true,
  },
  {
    icon: AppstoreOutlined,
    label: 'portal',
    to: routesConstants.PORTALS_PAGE_URL,
  },
  {
    icon: BlockOutlined,
    label: 'extractors',
    to: routesConstants.EXTRACTORS_PAGE_URL,
  },
  {
    icon: LineChartOutlined,
    label: 'reports',
    to: routesConstants.REPORTS_PAGE_URL,
  },
];

const AppSidebar = (): JSX.Element | null => {
  const [open, toggleOpen] = useToggle();
  const { mutate: logout } = useLogout();
  const onLogout = useCallback(() => {
    toggleOpen();
    logout();
  }, [logout, toggleOpen]);
  const { mutate: exitImpersonate } = useExitImpersonate();
  const onExitImpersonate = useCallback(() => {
    toggleOpen();
    exitImpersonate();
  }, [exitImpersonate, toggleOpen]);
  const showUserManagement = useShowUserManagement();
  const showExitImpersonate = useIsImpersonate();
  const accountMenuItems = useMemo(
    () => [
      {
        key: routesConstants.SETTINGS_PAGE_ID,
        label: (
          <NavLink onClick={toggleOpen} to={routesConstants.ACCOUNT_SETTINGS_PAGE_URL}>
            <div>Settings</div>
          </NavLink>
        ),
      },
      {
        key: 'subscription',
        label: (
          <NavLink onClick={toggleOpen} to={routesConstants.ACCOUNT_BILLING_PAGE_URL}>
            <div>Subscription</div>
          </NavLink>
        ),
      },
      ...(showUserManagement
        ? [
            {
              key: routesConstants.USER_MANAGEMENT_PAGE_ID,
              label: (
                <NavLink onClick={toggleOpen} to={routesConstants.ACCOUNT_USER_MANAGEMENT_URL}>
                  <div>User Management</div>
                </NavLink>
              ),
            },
          ]
        : []),
      ...(showExitImpersonate
        ? [
            {
              key: 'exit-impersonate',
              label: <div onClick={onExitImpersonate}>Exit Impersonate</div>,
            },
          ]
        : []),
      {
        key: 'logout',
        label: <div onClick={onLogout}>Logout</div>,
      },
    ],
    [onLogout, onExitImpersonate, showExitImpersonate, showUserManagement, toggleOpen],
  );

  const getLinkClass = useCallback(
    ({ isActive }: { isActive: boolean }) => (isActive ? `${defaultLinkClass} ${activeLinkClass}` : defaultLinkClass),
    [],
  );

  return (
    <aside className={containerClass}>
      <nav className="flex flex-column align-center full-width">
        {topItems.map(({ icon: Icon, label, end, ...restProps }) => (
          <NavLink {...restProps} className={getLinkClass} end={end} key={label}>
            <Icon className={styles.sidebarIcon} />
            <div className={styles.sidebarLabel}>{label}</div>
          </NavLink>
        ))}
      </nav>
      <nav className="flex flex-column align-center full-width">
        <NavLink className={getLinkClass} onClick={disabledFn} to={routesConstants.ACCOUNT_PAGE_URL}>
          <Popover
            content={<Menu className={styles.accountMenu} items={accountMenuItems} mode="vertical" />}
            onOpenChange={toggleOpen}
            open={open}
            placement="right"
            trigger="click"
          >
            <div className="flex flex-column align-center justify-center full-width">
              {open ? (
                <IdcardFilled className={styles.sidebarIconExternal} />
              ) : (
                <IdcardOutlined className={styles.sidebarIconExternal} />
              )}
              <div className={styles.sidebarLabel}>{routesConstants.ACCOUNT_PAGE_ID}</div>
            </div>
          </Popover>
        </NavLink>
        <a className={defaultLinkClass} href={HELP_URL} rel="noreferrer noopener" target="_blank">
          <QuestionCircleOutlined className={styles.sidebarIconExternal} />
          <div className={styles.sidebarLabel}>Help</div>
        </a>
      </nav>
    </aside>
  );
};

export default AppSidebar;
