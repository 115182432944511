import { Role } from '@import-io/types';

import { useHasRole } from 'common/hooks/use-has-role';
import { useIsImpersonate } from 'common/hooks/use-is-impersonate';
import { useSubscriptionFeatureFlags } from 'features/user/subscription/subscription-hooks';

export const useShowUserManagement = (): boolean => {
  const isSupport = useHasRole(Role.ADMIN, Role.SUPPORT);
  const isOrgAdmin = useHasRole(Role.ORG_ADMIN);
  const isImpersonated = useIsImpersonate();
  const { orgManagement } = useSubscriptionFeatureFlags();
  return isSupport || (orgManagement && isOrgAdmin && isImpersonated);
};
