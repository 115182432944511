import { useCallback, useMemo } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useLocation, useMatch, useParams } from 'react-router-dom';

import CopyOutlined from '@ant-design/icons/CopyOutlined';
import DeleteOutlined from '@ant-design/icons/DeleteOutlined';
import EyeFilled from '@ant-design/icons/EyeFilled';
import PlayCircleFilled from '@ant-design/icons/PlayCircleFilled';
import QuestionCircleFilled from '@ant-design/icons/QuestionCircleFilled';
import SettingFilled from '@ant-design/icons/SettingFilled';
import { endpoints, objectStoreApi, runReportApi } from '@import-io/js-sdk';
import { isGuid } from '@import-io/typeguards';
import type { Report, ReportType } from '@import-io/types/report-types';
import { Role } from '@import-io/types/user-types';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import message from 'antd/lib/message';
import Modal from 'antd/lib/modal';

import { history } from 'app/app-history';
import type { AppDispatch } from 'app/app-types';
import { runSelectedReport, toggleAutoPublish } from 'app/dash-old/actions/reports';
import { REPORT_FEATURES } from 'app/dash-old/lib/constants/reports';
import type { PageHeaderAction, PageHeaderLinkProps } from 'common/components/layout/header/layout-header-types';
import { useHasRole } from 'common/hooks/use-has-role';
import { useIsOwner } from 'common/hooks/use-is-owner';
import * as routesConstants from 'common/routes/routes-constants';
import { HISTORY_PAGE_ID, REPORTS_PAGE_URL } from 'common/routes/routes-constants';
import { GET_REPORT_QUERY_KEY } from 'features/reports/common/reports-constants';
import { selectReportHistory } from 'features/reports/common/reports-selectors';
import type { ExtendedReport, ReportHistoryState } from 'features/reports/common/reports-types';
import { getReportHistoryUrl, getReportSettingsUrl } from 'features/reports/common/reports-utils';
import { REPORT_LIST_QUERY_KEY } from 'features/reports/list/report-list-constants';

export const useAfterDelete = (reportId: string): ((arg: any) => void) => {
  const queryClient = useQueryClient();

  return useCallback(async () => {
    const reports = queryClient.getQueryData<Report[]>(REPORT_LIST_QUERY_KEY) || [];
    const newReports = reports.filter((r) => r.guid !== reportId);
    queryClient.setQueryData(REPORT_LIST_QUERY_KEY, newReports);

    await queryClient.invalidateQueries({ queryKey: [GET_REPORT_QUERY_KEY, reportId], refetchType: 'none' });
    void message.success('Report successfully deleted');
    const url = newReports.length > 0 ? getReportHistoryUrl(newReports[0]!.guid) : REPORTS_PAGE_URL;
    history.push(url);
  }, [reportId, queryClient]);
};

const deleteError = 'Delete report error';

export const useDeleteAction = (isOwner: boolean): PageHeaderAction => {
  const reportId = useParams()['guid']!;
  const onSuccess = useAfterDelete(reportId);

  const { isPending, mutate } = useMutation({
    mutationFn: () => runReportApi.archive(reportId),
    onSuccess: onSuccess,
    onError: (e) => {
      void message.error(deleteError);
      console.log(deleteError, e);
    },
  });

  const deleteReport = useCallback(() => {
    Modal.confirm({
      cancelText: 'No',
      okText: 'Yes, delete my report',
      onOk: () => mutate(),
      title: 'This action cannot be undone. Are you sure?',
      type: 'warning',
    });
  }, [mutate]);

  return useMemo(
    () => ({
      disabled: !isOwner || isPending,
      icon: <DeleteOutlined />,
      label: 'Delete',
      onClick: deleteReport,
    }),
    [deleteReport, isPending, isOwner],
  );
};

const duplicateError = 'Duplicate report error';

export const useDuplicateAction = (type: ReportType, isOwner: boolean): PageHeaderAction => {
  // TODO: migrate REPORT_FEATURES constant to TypeScript
  // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
  const features = REPORT_FEATURES[type] || {};
  const canDuplicate = features.canDuplicate === true || !isOwner;
  const match = useMatch(routesConstants.REPORT_URL);
  const reportId = match?.params.guid;
  const queryClient = useQueryClient();

  const { isPending, mutate } = useMutation({
    mutationFn: () => {
      const data = queryClient.getQueryData([GET_REPORT_QUERY_KEY, reportId]);
      const { extractorId, latestConfigId, name } = data as Report;
      return objectStoreApi.report.create({
        extractorId: extractorId,
        latestConfigId: latestConfigId,
        name: `Copy of ${name}`,
        type: type,
      });
    },
    onSuccess: (newReport: Report) => {
      const { guid } = newReport;
      queryClient.setQueryData([GET_REPORT_QUERY_KEY, newReport.guid], newReport);
      const reports = queryClient.getQueryData<Report[]>(REPORT_LIST_QUERY_KEY) || [];
      const newReports = [newReport, ...reports];
      queryClient.setQueryData(REPORT_LIST_QUERY_KEY, newReports);

      void message.success('Report successfully duplicated');
      history.push(getReportHistoryUrl(guid));
    },
    onError: (e) => {
      void message.error(duplicateError);
      console.log(duplicateError, e);
    },
  });

  const onClick = useCallback(() => mutate(), [mutate]);

  return useMemo(
    () => ({
      disabled: !canDuplicate || isPending,
      icon: <CopyOutlined />,
      label: 'Duplicate',
      onClick: onClick,
      tooltip: 'Create a copy of this report',
    }),
    [canDuplicate, isPending, onClick],
  );
};

export const useReportHeaderActions = (report: ExtendedReport): PageHeaderAction[] => {
  const dispatch = useDispatch<AppDispatch>();
  const { pathname } = useLocation();
  const { extractorRuns }: ReportHistoryState = useSelector(selectReportHistory, shallowEqual);
  const { autoPublish, startingRun = false, togglingAutoPublish = false, type } = report;
  const isOwner = useIsOwner(report);
  // TODO: migrate REPORT_FEATURES constant to TypeScript
  // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
  const features = REPORT_FEATURES[type] || {};
  const canRun = isOwner && !startingRun && extractorRuns.length >= features.minExtractorRuns;
  const canAutoPublish = isOwner && !togglingAutoPublish;
  const isHistory = pathname.includes(HISTORY_PAGE_ID);
  const runReport = useCallback(() => dispatch(runSelectedReport()), [dispatch]);
  const togglePublish = useCallback(() => dispatch(toggleAutoPublish()), [dispatch]);
  const duplicateAction = useDuplicateAction(type, isOwner);
  const deleteAction = useDeleteAction(isOwner);

  return useMemo<PageHeaderAction[]>(
    () => [
      ...(isHistory
        ? [
            {
              disabled: !canRun,
              icon: <PlayCircleFilled />,
              label: startingRun ? 'Starting...' : 'Run Report',
              onClick: runReport,
              type: 'primary',
            } as PageHeaderAction,
          ]
        : []),
      {
        disabled: !canAutoPublish,
        label: autoPublish ? 'Disable auto publishing' : 'Enable auto publishing',
        onClick: togglePublish,
        tooltip: autoPublish ? 'Stop sharing this report to portal' : 'Automatically share this report to portal',
      },
      duplicateAction,
      deleteAction,
    ],
    [autoPublish, canAutoPublish, canRun, deleteAction, duplicateAction, isHistory, runReport, startingRun, togglePublish],
  );
};

const reportSupportUrl = `https://hades.${endpoints.rootDomain}/store/report`;

export const useReportHeaderTabs = (reportId?: string): PageHeaderLinkProps[] => {
  const isSupport = useHasRole(Role.ADMIN, Role.SUPPORT);
  return useMemo<PageHeaderLinkProps[]>(() => {
    if (!isGuid(reportId)) {
      return [];
    }

    const defaultTabs: PageHeaderLinkProps[] = [
      {
        icon: <EyeFilled />,
        label: `run history`,
        to: getReportHistoryUrl(reportId),
      },
      {
        icon: <SettingFilled />,
        label: 'settings',
        to: getReportSettingsUrl(reportId),
      },
    ];
    return isSupport
      ? [
          ...defaultTabs,
          {
            href: `${reportSupportUrl}/${reportId}`,
            icon: <QuestionCircleFilled />,
            label: 'support',
            target: '_blank',
          },
        ]
      : defaultTabs;
  }, [isSupport, reportId]);
};
